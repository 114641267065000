import { Paper, Container } from "@material-ui/core";
import React from "react";

export default function Features() {
  return (
    <Paper id="features" elevation={20}>
      <Container className="textSection" maxWidth="md">
        <h1>Funktionen</h1>
        <h3>
          JAY ist primär für den Einsatz innerhalb einer Psychotherapie
          konzipiert.
        </h3>
        <p>
          JAY ist als Therapiebegleitung gedacht und sollte unter Anleitung
          einer Therapeutin/eines Therapeuten verwendet werden. JAY dient neben
          der Förderung des Transfers von Bewältigungsstrategien, die in der
          Therapie erarbeitet wurden, auch der Unterstützung einer spezifischen
          Diagnostik und der Verlaufskontrolle. JAY kann spezifische
          Informationen über vom Jugendlichen erlebte Problemsituationen und
          Emotionen im Alltag liefern und den Transfer von erarbeiteten
          Bewältigungsstrategien in den Alltag der Patientinnen/Patienten
          erleichtern und überprüfen und die Motivation der
          Patientinnen/Patienten zur Durchführung von Therapieaufgaben erhöhen.
          Weiterhin bietet JAY eine Reihe von Bewältigungsstrategien an, die
          individuell erweitert werden können und in schwierigen Situationen
          Hilfe leisten können. Die App ist für den Altersbereich von 13 bis 17
          Jahre konzipiert.
        </p>
        <h3>Sprechen sie auch mit den Eltern!</h3>
        <p>
          Die Nutzung der App in einem therapeutischen Rahmen sollte auch mit
          den Bezugspersonen der Patientinnen/Patienten gut abgesprochen werden.
          Die Einträge der Patientinnen/Patienten in den einzelnen Funktionen
          werden verschlüsselt auf dem Smartphone gespeichert und sind nur in
          der App einsehbar. Lediglich bei Export der Dateien, liegen diese in
          einem externen Ordner. Die Dateien sind dann nicht mehr geschützt und
          sollten schnellstmöglich wieder gelöscht werden, um Zugriff von außen
          zu verhindern. Daher scheint es wichtig mit den Patientinnen/Patienten
          über die Vertraulichkeit der von ihnen in die App eingegebenen
          Informationen zu sprechen und sie dafür zu sensibilisieren, auf ihr
          Smartphone und auf ihre Daten zu achten. Weitere Informationen dazu
          finden sie auch im Bereich <a href="privacy">Datenschutz</a>.
        </p>
        <p>
          Aktuell werden noch kleine Veränderungen an der App durchgeführt.
          Achten Sie daher darauf, dass Ihr Patient bzw. Ihre Patientin jeweils
          die aktuellste Version der App nutzt. Die jeweiligen Updates werden
          jeweils auch in einer Erweiterung des Manuals zur App (siehe{" "}
          <a href="#manual">PDF zum Download</a>) dokumentiert und erklärt.
          Achten Sie darauf, dass Sie immer die neueste Version des Manuals zur
          App zur Verfügung haben.
        </p>
      </Container>
    </Paper>
  );
}

import { gsap } from "gsap";
import React, { useEffect, useRef } from "react";
import useResizeObserver from "use-resize-observer";

export default function ParallaxContainer(props) {
  let { children, background } = props;

  const backgroundRef = useRef();
  const containerRef = useRef();
  const { width, height } = useResizeObserver({ ref: containerRef });

  useEffect(() => {
    const background = backgroundRef.current;
    const container = containerRef.current;
    const tl = gsap.timeline({
      scrollTrigger: {
        id: "parallax",
        trigger: container,
        start: "top bottom",
        end: "bottom top",
        //markers: { indent: 5 },
        scrub: true,
      },
    });
    tl.fromTo(
      background,
      { filter: "blur(10px)", opacity: 0.5 },
      { filter: "blur(0px)", opacity: 1, duration: 0.1 }
    );
    tl.fromTo(background, { y: "-100px" }, { y: "100px" }, "<");
    tl.fromTo(
      background,
      { filter: "blur(0px)" },
      { filter: "blur(10px)", duration: 0.05 },
      "-=0.05"
    );
    return () => {
      tl.kill();
    };
  }, []);

  if (typeof background == "string")
    background = <img src={background} alt="" />;

  if (children == null)
    children = [
      <div
        style={{
          height: "60vh",
          width: "100vw",
          background: "transparent",
          //border: "2px solid red",
        }}
      ></div>,
    ];
  //   console.log("size: ", width, height);
  return (
    <div ref={containerRef} style={{ background: "black" }}>
      {React.Children.map(children, (child) => {
        return (
          <div
            style={{
              ...props.style,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <background.type
              ref={backgroundRef}
              {...background.props}
              alt=""
              style={{
                objectFit: "cover",
                left: 0,
                top: "-100px",
                zIndex: 99,
                width: width || 0,
                height: (height || 0) + 200,
                position: "absolute",
                ...background.props.style,
              }}
            />
            <child.type
              {...child.props}
              style={{
                ...child.props.style,
                position: "relative",
                zIndex: 100,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

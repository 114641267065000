import { Paper, Button, Container } from "@material-ui/core";
import React from "react";

export default function Manual() {
  return (
    <Paper elevation={20} id="manual">
      <Container className="textSection" maxWidth="md">
        <h1>App-Anleitung</h1>
        <p>
          Sie haben hier die Möglichkeit ein Manual zur App im PDF-Format
          herunterzuladen.
        </p>
        <Button
          variant="contained"
          color="primary"
          download
          href="jay_manual-2022_10_26.pdf"
          target="_blank"
        >
          Anleitung herunterladen
        </Button>
        <p>
          Das Manual soll Ihnen die Nutzung der Therapie-App JAY erleichtern.
          Die einzelnen Funktionen und deren Einsatzmöglichkeiten werden
          dargestellt und die Möglichkeiten zur individuellen Einstellung der
          Funktionen ausführlich beschrieben.
        </p>
      </Container>
    </Paper>
  );
}

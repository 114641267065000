import "./theme/App.css";
import ParallaxContainer from "./components/parallaxContainer/ParallaxContainer";
import Header from "./sections/Header";
import Hero from "./sections/Hero";
import Manual from "./sections/Manual";
import Features from "./sections/Features";
import Books from "./sections/Books";
import Footer from "./sections/Footer";
import { Route, Switch } from "react-router";
import Imprint from "./sections/Imprint";
import Privacy from "./sections/Privacy";

function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/imprint">
          <Imprint />
        </Route>
        <Route path="/">
          <Hero key="hero" />
          <ParallaxContainer background="fullscreen_pic_1.jpg" />
          <Manual />
          <ParallaxContainer background="fullscreen_pic_3.jpg" />
          <Features />
          <ParallaxContainer background="fullscreen_pic_2.jpg" />
          <Books />
          <ParallaxContainer background="fullscreen_pic_4.jpg" />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;

import { Button, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Header() {
  const matches = useMediaQuery("(min-width:400px)");

  return (
    <div
      style={{
        top: 0,
        height: 90,
        width: "100vw",
        background: "#8AC5EF88",
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
        zIndex: 10000,
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
        boxShadow: "inset 0 0 4px #0004",
      }}
    >
      <Link to="/">
        <img
          src="logo_small.png"
          alt="JAY Logo"
          style={{
            height: 60,
          }}
        />
      </Link>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <Button
          size="small"
          color="secondary"
          component={HashLink}
          to="/#manual"
        >
          Manual
        </Button>
        <Button
          size="small"
          color="secondary"
          component={HashLink}
          to="/#features"
        >
          Funktionen
        </Button>
        <Button
          size="small"
          color="secondary"
          component={HashLink}
          to="/#books"
        >
          Kombination
        </Button>
        {matches && (
          <Button size="small" color="secondary" component={Link} to="imprint">
            Impressum
          </Button>
        )}
        {matches && (
          <Button size="small" color="secondary" component={Link} to="privacy">
            Datenschutz
          </Button>
        )}
      </div>
    </div>
  );
}

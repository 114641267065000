import { useMediaQuery } from "@material-ui/core";
import React from "react";

export default function Book(props) {
  const { left, title, subtitle, body, picture } = props;
  const matches = useMediaQuery("(min-width:400px)");

  return (
    <div
      style={{
        textAlign: "left",
        background: "#fff3",
        border: "1px solid #fff5",
        boxShadow: "inset 0 0 3px #0002",
        padding: 20,
        borderRadius: 10,
        marginBottom: 20,
      }}
    >
      <h3 style={{ marginBottom: 0 }}>{title}</h3>
      <h4 style={{ marginTop: 0 }}>{subtitle}</h4>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: left ? "row-reverse" : "row",
          flexWrap: matches ? "nowrap" : "wrap",
        }}
      >
        <img
          src={picture}
          alt=""
          width="600"
          style={{
            objectFit: "contain",
            maxWidth: 200,
            padding: left ? "0px 0px 20px 20px" : "0px 20px 20px 0px",
          }}
        />

        {body}
      </div>
    </div>
  );
}

import { gsap } from "gsap";
import React, { useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export default function Hero() {
  const rootRef = useRef();
  useEffect(() => {
    const root = rootRef.current;
    gsap.fromTo(
      root.querySelector(".heroContent"),
      {
        opacity: 0,
        scale: 1.1,
      },
      { opacity: 1, delay: 1, scale: 1, duration: 0.5, ease: "power2.inOut" }
    );
    gsap.fromTo(
      root.querySelector(".jay-ce-logo"),
      {
        opacity: 0,
        scale: 1.3,
        rotation: 30,
      },
      {
        opacity: 1,
        delay: 2,
        scale: 1,
        duration: 0.5,
        rotation: 20,
        ease: "Elastic.easeOut",
      }
    );
    const tl = gsap.timeline({
      scrollTrigger: {
        id: "hero",
        trigger: root,
        start: "start start",
        //end: "bottom top",
        end: "+=2000",
        // markers: { indent: 5 },
        pin: root.querySelector(".container"),
        scrub: 0.01,
      },
    });
    tl.to(root.querySelector("h1"), {
      opacity: 0.01,
      y: -100,
      ease: "power2.inOut",
    });

    tl.fromTo(
      root.querySelector(".jay-showcase"),
      { scale: 1, autoAlpha: 1 },
      { scale: 1.3, autoAlpha: 0.01, ease: "power2.inOut" },
      "<"
    );

    tl.to(
      root.querySelector(".appStoreLinks"),
      { scale: 1.3, autoAlpha: 0.01, ease: "power2.inOut" },
      "<"
    );

    tl.fromTo(
      root.querySelector(".welcomeText"),
      { autoAlpha: 0 },
      { autoAlpha: 1 },
      "-=0.2"
    );
    tl.fromTo(
      root.querySelector(".welcomeText"),
      { scale: 0.8 },
      { scale: 1, duration: 0.8 },
      "<"
    );
    // tl.to(root.querySelector(".appStoreLinks"), { duration: 0.8 }, "<");
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div ref={rootRef}>
      <div
        className="container"
        style={{
          height: "100vh",
          width: "100vw",
          background: "white",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          className="background"
          src="background.jpg"
          alt="background"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "blur(2px)",
          }}
        />
        <div
          className="heroContent"
          style={{
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            paddingBottom: 50,
            opacity: 0,
            background:
              "linear-gradient(0deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.1) 100%)",
          }}
        >
          <div
            className="startContent"
            style={{
              paddingTop: 90,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              position: "relative",
            }}
          >
            <h1
              style={{
                color: "#384FBF",
                WebkitTransformStyle: "preserve-3d",
                transformStyle: "preserve-3d",
                transformPerspective: 1000,
              }}
            >
              JAY - Journaling App for Youth
            </h1>
            <div className="jay-showcase">
              <img
                src="jay-showcase.png"
                alt="jay"
                width="920"
                height="540"
                style={{
                  maxWidth: "70%",
                  minWidth: "70%",
                  minHeight: 100,
                  maxHeight: "50vh",
                  objectFit: "contain",
                  WebkitTransformStyle: "preserve-3d",
                  transformStyle: "preserve-3d",
                  transformPerspective: 1000,
                }}
              />
              <h2
              style={{
                color: "#384FBF",
                WebkitTransformStyle: "preserve-3d",
                transformStyle: "preserve-3d",
                transformPerspective: 1000,
              }}
            >
               Leider erfahren wir aktuell Probleme mit dem Google PlayStore, sodass die App auf Geräten mit einem Android-Betriebssystem momentan nicht zur Verfügung steht. Wir arbeiten intensiv an einer Lösung und informieren Sie gerne hier, wenn die App im Google PlayStore wieder verfügbar ist.
            </h2>
            </div>
            <div
              className="appStoreLinks"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 50,
                WebkitTransformStyle: "preserve-3d",
                transformStyle: "preserve-3d",
                transformPerspective: 1000,
              }}
            >
              <a
                href="https://apps.apple.com/de/app/jay/id1552174214"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ width: 150 }}
                  src="ios.png"
                  alt="Apple AppStore Link"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=de.jay_app.jay"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ width: 150 }}
                  src="google_play.png"
                  alt="Google PlayStore Link"
                />
              </a>
            </div>
          </div>
          <div
            className="welcomeText"
            style={{
              position: "absolute",
              padding: 30,
              top: 0,
              left: 0,
              height: "100vh",
              width: "100vw",
              display: "flex",
              opacity: 0,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              WebkitTransformStyle: "preserve-3d",
              transformStyle: "preserve-3d",
              transformPerspective: 1000,
            }}
          >
            <div style={{ maxWidth: 1000 }}>
              <h1>Willkommen auf jay-app.de</h1>
              <p>
                JAY (Journaling App for Youth) ist eine für Jugendliche von 13
                bis 17 Jahren entwickelte App, die therapieunterstützend
                eingesetzt werden kann.
              </p>

              <p>
                Die App kann bei verschiedenen Störungsbildern im Jugendalter
                eingesetzt werden: <br />
                Störungen des Sozialverhaltens (Aggressives Verhalten,
                Oppositionelles Verhalten, Begrenzte prosoziale Emotionalität),{" "}
                <br />
                Angststörungen, <br />
                Depressive Störungen, <br />
                Zwangsstörungen und <br />
                Aufmerksamkeitsdefizit-/ Hyperaktivitätsstörungen.
              </p>
              <p>
                Auf dieser Seite finden approbierte und sich in Ausbildung
                befindliche Psychotherapeut*innen und Kinder- und
                Jugendlichenpsychotherapeut*innen eine Anleitung für den Einsatz
                der Therapie-App JAY.
              </p>
              <p>
                JAY ist ein gekennzeichnetes Medizinprodukt
                <img
                  style={{
                    height: "20px",
                    transform: "translate(8px, 4px)",
                  }}
                  alt="CE"
                  src="ce_black.png"
                />
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 100,
            display: "flex",
            width: "100vw",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <KeyboardArrowDownIcon fontSize="large" />
        </div>
      </div>
    </div>
  );
}

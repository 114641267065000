import { createMuiTheme } from "@material-ui/core";
import Color from "color";

// const theme = createMuiTheme({
// 	palette: {
// 		background: {
// 			dark: '#F4F6F8',
// 			default: colors.common.white,
// 			paper: colors.common.white
// 		},
// 		primary: {
// 			main: colors.indigo[500]
// 		},
// 		secondary: {
// 			main: colors.indigo[500]
// 		},
// 		text: {
// 			primary: colors.blueGrey[900],
// 			secondary: colors.blueGrey[600]
// 		}
// 	},
// 	shadows,
// 	typography
// });

const theme = function () {
  const _theme = createMuiTheme({
    palette: {
      primary: { main: "#0979FF", light: "#4097FF", dark: "#054CA1" },
      highlight: { main: "#B4FF00", light: "#D4FF6D", dark: "#7AAD00" },
      secondary: { main: "#384FBF" },
      disabled: "#48537B",
      type: "light",
    },
    borderRadius: 15,
    spacing: 10,
  });
  console.log(_theme);
  _theme.overrides = {
    MuiStandardInput: {
      input: {
        "&:-webkit-autofill": {
          "-webkit-box-shadow": `0 0 0 1000px ${_theme.palette.background.default} inset !important`,
        },
      },
    },

    MuiAppBar: {
      root: { border: "none" },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: `${Color(_theme.palette.appBackground)
          .alpha(0.8)
          .rgb()
          .toString()}`,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        padding: _theme.spacing(2),
      },
      contained: {
        boxShadow: "inset 0 0 4px #0004",
        "&:hover": {
          boxShadow: "inset 0 0 4px #0006",
        },
        "&:active": {
          boxShadow: "inset 0 0 4px #0006",
        },
      },
      textSizeSmall: {
        padding: "4px 5px",
        fontSize: "0.7rem",
      },
    },
    MuiPaper: {
      root: {
        background:
          "linear-gradient(0deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 100%), url(background.jpg)",
        backgroundSize: "cover",
        // background: `linear-gradient(0deg, ${theme.palette.background.paper} 0%, ${Color(
        // 	theme.palette.background.paper
        // )
        // 	.lighten(0.3)
        // 	.hex()} 100%)`,
        // backgroundColor: theme.palette.background.paper,
        // border: `solid 1px ${Color(theme.palette.background.paper).lighten(5).hex()}22`
      },
    },
    MuiListItem: {
      root: {},
    },
    MuiListItemText: {
      primary: {
        fontWeight: 600,
        color: _theme.palette.text.disabled,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 10,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
      },
    },
    MuiTooltip: {
      tooltip: {
        color: _theme.palette.primary.main,
        padding: 10,
        borderStyle: "solid",
        borderRadius: 10,
        borderWidth: 1,
        fontSize: 12,
      },
    },
    MuiFormLabel: { root: { fontWeight: 500 } },
    MuiFormHelperText: { root: { fontWeight: 500 } },
    MuiInputBase: { root: { fontWeight: 500 } },
  };
  return _theme;
};

export default theme;

import { Paper, Container } from "@material-ui/core";
import React from "react";
import Book from "../components/Book";

export default function Books() {
  return (
    <Paper elevation={20} id="books">
      <Container className="textSection" maxWidth="md">
        <h1>
          JAY in Kombination mit verhaltenstherapeutischen Behandlungsprogrammen
        </h1>
        <p>
          JAY kann besonders gut in Kombination mit verhaltenstherapeutischen
          Behandlungsprogrammen wie z.B. den Therapieprogrammen für Jugendliche
          mit Selbstwert-, Leistungs- und Beziehungsstörungen{" "}
          <span className="bookquote">
            (SELBST Band 1: Grundlagen der Selbstmanagementtherapie bei
            Jugendlichen, Walter et al., 2007 ; SELBST Band 2: Leistungsprobleme
            im Jugendalter, Walter & Döpfner, 2009; SELBST Band 3: Dresbach &
            Döpfner, 2020)
          </span>{" "}
          und den Therapieprogrammen für Kinder und Jugendliche mit Angst- und
          Zwangsstörungen{" "}
          <span className="bookquote">
            (THAZ Band 1: Leistungsängste – Therapieprogramm für Kinder und
            Jugendliche mit Angst- und Zwangsstörungen, Suhr-Dachs & Döpfner,
            2015; THAZ Band 2: Soziale Ängste - Therapieprogramm für Kinder und
            Jugendliche mit Angst- und Zwangsstörungen, Büch & Döpfner, 2011)
          </span>{" "}
          eingesetzt werden.
        </p>

        <div style={{ marginTop: 50 }}>
          <h2 style={{ marginBottom: 0 }}>SELBST</h2>
          <h3 style={{ marginTop: 0 }}>
            Therapieprogramm zur Behandlung von Jugendlichen mit Selbstwert-,
            Leistungs- und Beziehungsstörungen
          </h3>
        </div>

        <Book
          title="SELBST Band 1:"
          subtitle="Grundlagen der Selbstmanagementtherapie bei Jugendlichen "
          picture="selbst_1.jpg"
          body={
            <div>
              SELBST (zu beziehen unter{" "}
              <a href="https://www.hogrefe.de/shop/leistungsaengste-66704.html">
                www.hogrefe.de
              </a>
              ) ist ein Therapieprogramm zur Behandlung von Jugendlichen mit
              Selbstwert-, Leistungs- und Beziehungsstörungen im Alter von 12
              bis 18 Jahren. SELBST ist störungsübergreifend, problem-,
              ressourcen- und lösungsorientiert. Es integriert jugendlichen-,
              eltern- und lehrerzentrierte Interventionen. Der Band 1 erläutert
              die Grundlagen des Therapieprogramms und stellt ausführlich den
              Selbstmanagementansatz und seine Anwendung in der
              verhaltenstherapeutischen Arbeit mit Jugendlichen vor.
              Umfangreiche Materialien zur therapeutischen Arbeit mit dem
              Jugendlichen und seinen Bezugspersonen sind enthalten.
            </div>
          }
        />

        <Book
          left={true}
          title="SELBST Band 2:"
          subtitle="Leistungsprobleme im Jugendalter "
          picture="selbst_2.jpg"
          body={
            <div>
              Band 2 (zu beziehen unter{" "}
              <a href="https://www.hogrefe.de/shop/leistungsaengste-66704.html">
                www.hogrefe.de
              </a>
              ) erläutert die Behandlung von Jugendlichen mit Leistungsproblemen
              in der Schule oder in der Ausbildung, die nicht primär durch
              Begabungsdefizite oder Teilleistungsstörungen erklärbar sind.
              SELBST-Leistungsprobleme basiert auf dem Selbstmanagementansatz
              und integriert jugendlichen-, eltern- und lehrerzentrierte
              Interventionen. Es besteht aus vier Therapiebausteinen, die darauf
              abzielen, die Leistungsmotivation zu fördern, planerische
              Fertigkeiten und Lernstrategien zu vermitteln, die Mitarbeit im
              Unterricht zu verbessern und Strategien zum Aufholen verpasster
              Lerninhalte aufzuzeigen.
            </div>
          }
        />
        <Book
          title="SELBST Band 3:"
          subtitle="Gleichaltrigenprobleme im Jugendalter"
          picture="selbst_3.jpg"
          body={
            <div>
              Der dritte Band des Therapieprogramms SELBST (zu beziehen unter{" "}
              <a href="https://www.hogrefe.de/shop/leistungsaengste-66704.html">
                www.hogrefe.de
              </a>
              ) ist ein praxisorientierter Leitfaden zur Behandlung von
              Gleichaltrigenproblemen. Jugendliche, die in sozialen Situationen
              mit dysfunktionalen Verhaltensweisen reagieren, erhalten
              Strategien fürs Selbstmanagement. Im Zentrum stehen kognitive,
              emotionsaktivierende und verhaltensverändernde Interventionen mit
              dem Ziel soziale Kompetenzen aufzubauen. Im Zentrum stehen
              kognitive, emotionsaktivierende und verhaltensverändernde
              Interventionen. Die sieben problemspezifischen Therapiebausteine
              dienen der Schulung der sozialen Informationsverarbeitung, der
              Bearbeitung zugrunde liegender interpersonaler Schemata sowie dem
              Aufbau sozialer Kompetenzen bei den Jugendlichen. Eltern und
              Lehrkräfte werden aktiv in die Behandlung einbezogen.
            </div>
          }
        />

        <div style={{ marginTop: 50 }}>
          <h2 style={{ marginBottom: 0 }}>THAZ</h2>
          <h3 style={{ marginTop: 0 }}>
            Therapieprogramme für Kinder und Jugendliche mit Angst- und
            Zwangsstörungen
          </h3>
        </div>

        <Book
          title="THAZ Band 1:"
          subtitle="Leistungsängste"
          picture="thaz_1.jpg"
          body={
            <div>
              Leistungsängste – Therapieprogramm für Kinder und Jugendliche mit
              Angst- und Zwangsstörungen, Suhr-Dachs & Döpfner, 2015 Band 1 des
              Therapieprogramms für Kinder und Jugendliche mit Angst- und
              Zwangsstörungen (THAZ) (zu beziehen unter{" "}
              <a href="https://www.hogrefe.de/shop/leistungsaengste-66704.html">
                www.hogrefe.de
              </a>
              ) ist der multimodalen Therapie von Kindern und Jugendlichen mit
              Leistungsängsten gewidmet. Die Interventionen umfassen
              patientenzentrierte sowie familien- und umfeldzentrierte
              Interventionen. Die Behandlungskomponenten sprechen sowohl die
              kognitive, die emotional-physiologische als auch die behaviorale
              Ebene von Leistungsängsten an.
            </div>
          }
        />

        <Book
          left={true}
          title="THAZ Band 2:"
          subtitle="Soziale Ängste"
          picture="thaz_2.jpg"
          body={
            <div>
              Soziale Ängste - Therapieprogramm für Kinder und Jugendliche mit
              Angst- und Zwangsstörungen, Büch & Döpfner, 2011 Das
              Therapieprogramm THAZ – Soziale Ängste (zu beziehen über{" "}
              <a href="https://www.hogrefe.de/shop/soziale-aengste-65724.html">
                www.hogrefe.de
              </a>
              ) ist ein modular aufgebautes Behandlungsprogramm, das aus kind-
              und elternzentrierten Interventionen besteht, dessen Methoden die
              kognitive, behaviorale und emotional-physiologische Ebene von
              Sozialen Ängsten ansprechen. Je nach Manifestation der Störung
              können die einzelnen Bausteine individuell angepasst werden. Das
              Programm ist primär für die Behandlung von Kindern und
              Jugendlichen im Alter von 8 bis 14 Jahren konzipiert. Kern dieses
              Behandlungskonzeptes stellt die situationsgeleitete Modifikation
              von problemaufrechterhaltenden Prozessen dar. Zahlreiche
              Arbeitsmaterialien und ausführliche Hinweise zur Durchführung der
              beiden Therapieprogramme erleichtern die Umsetzung in die Praxis.
            </div>
          }
        />

        <div style={{ marginTop: 50, marginBottom: 50, fontSize: 16 }}>
          <p>
            Durch den zusätzlichen Einsatz von JAY, kann die Effektivität der
            verhaltenstherapeutischen Interventionen der verschiedenen
            Behandlungsprogramme möglicherweise sogar noch verbessert werden.
            Wir hoffen, damit optimale Voraussetzungen für eine
            individualisierte multimodale Therapie und für einen guten
            Behandlungserfolg zu schaffen.
          </p>
          <p>Anja Görtz-Dorten und Manfred Döpfner</p>
        </div>
      </Container>
    </Paper>
  );
}

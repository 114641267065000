import { Paper, Container, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Paper elevation={20}>
      <Container
        className="textSection"
        maxWidth={false}
        style={{
          height: 20,
          fontSize: 12,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>©2021 Anja Görtz-Dorten und Manfred Döpfner</div>
        <div>
          <Button size="small" color="secondary" component={Link} to="imprint">
            Impressum
          </Button>
          <Button size="small" color="secondary" component={Link} to="privacy">
            Datenschutz
          </Button>
        </div>
      </Container>
    </Paper>
  );
}
